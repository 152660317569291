/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

/* Fonts */
@font-face {
    font-family: "Kashima";
    src: url("../fonts/SVN-Kashima-Brush.otf") format("opentype");
    font-weight: normal;
}
@font-face {
    font-family: "Cezanne";
    src: url("../fonts/cezanneregular.ttf") format("truetype");
    font-weight: normal;
}